<template>
  <div class="container-fluid p-dash-cliente-master pb-2 pt-3" >
    <div class="col-12">
        <div class="row">
            <div class="col-auto align-self-center text-right pr-0">
                <router-link :to="{name: 'Report'}" class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta"><i class="fas fa-arrow-left"></i></router-link>
            </div>
            <div class="col-10 align-self-center">
                <h3 class="page-title text-truncate text-dark font-weight-medium mb-1 d-none">Editando chamado</h3>
                <div class="d-flex align-items-center">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb m-0 p-0">
                            <li class="breadcrumb-item"><router-link :to="{name: 'Dashboard'}">dashboard</router-link> / <router-link :to="{name: 'Report'}">assistência técnica</router-link> / {{ report.subject }}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 pt-1 px-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-9 pt-3 order-2 order-md-1 order-lg-1">
                    <div class="card border-right card-bg-dash">
                        <div class="card-body">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12 logo-edit-chamado-cliente">
                                        <img v-if="report.enterprise.image" :src="report.enterprise.image" alt="Logo" style="max-height: 30px;">
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4 pt-4">
                                            <p class="page-txt-view-chamado">Aberto em</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.created_at | date }}</h3>
                                        </div>
                                        <div class="col-12 pt-4">
                                            <p class="page-txt-view-chamado">Assunto</p>
                                            <h3 class="page-title-view-chamado-2 font-weight-medium mb-1">{{ report.subject }}</h3>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4 pt-4">
                                            <p class="page-txt-view-chamado">Chamado aberto como:</p>
                                            <label class="pl-0">{{ getOpenLike(report.open_like) }}</label>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.client.first_name }} {{ report.client.last_name }}</h3>
                                        </div>
                                        <div class="col-12 pt-4">
                                            <p class="page-txt-view-chamado">Imóvel</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.enterprise.name }}</h3>
                                        </div>
                                        <div class="col-12 pt-4"></div>
                                        <div class="col-12">
                                            <p class="page-txt-view-chamado">Condomínio</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.enterprise.name}}</h3>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-3 pt-4">
                                            <p class="page-txt-view-chamado">N˚ torre</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.tower }}</h3>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-3 pt-4">
                                            <p class="page-txt-view-chamado">N˚ apartamento</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.floors.map(elem => elem.unity.number).join(', ') }}</h3>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-3 pt-4">
                                            <p class="page-txt-view-chamado">Qual status do chamado?</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.status == 'PENDING' ? 'Novo' : (report.status == 'IN_PROGRESS' ? 'Em andamento' : 'Finalizado') }}</h3>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-3 pt-4">
                                            <p class="page-txt-view-chamado">Não conformidades</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.conformity.name }}</h3>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-3 pt-4">
                                            <p class="page-txt-view-chamado">Procedentes</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.precedent_id ? 'Sim' : 'Não' }}</h3>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-3 pt-4">
                                            <p class="page-txt-view-chamado">Data/Hora agendamento</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.date | date }}</h3>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-3 pt-4">
                                            <p class="page-txt-view-chamado">Técnico</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1" v-if="report.technical">{{ report.technical.first_name }} {{ report.technical.last_name }}</h3>
                                        </div>
                                        <div class="col-12 pt-4">
                                            <p class="page-txt-view-chamado">Observação</p>
                                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.description }}</h3>
                                        </div>

                                    </div>
                                </div>
                                <form novalidate @submit.prevent="save" v-if="report.status != 'FINISHED'">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col-12 col-md-12 col-lg-12 pt-3">
                                                <div class="form-group">
                                                    <label>Responder </label>
                                                    <textarea class="form-control" rows="6" placeholder="Mensagem" v-model="answer" v-validate="'required'" name="answer" :class="{'is-invalid': errors.has('answer')}" ></textarea>
                                                    <small id="textHelp" class="form-text text-muted">Informe detalhes sobre o chamado.</small>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-12 col-lg-12">
                                            <vue-upload-multiple-image
                                                @upload-success="uploadImageSuccess"
                                                @before-remove="beforeRemove"
                                                @edit-image="editImage"
                                                :data-images="images"
                                                idUpload="myIdUpload"
                                                editUpload="myIdEdit"
                                                dragText="Clique ou arraste um imagem aqui"
                                                browseText="Procurar imagem"
                                                :showPrimary="false"
                                                :showEdit="false"
                                                :multilple="false"
                                                :maxImage="1"
                                            ></vue-upload-multiple-image>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-actions">
                                        <div class="text-center">
                                            <button type="submit" class="btn btn-danger btn-relatar-problema">Responder <i class="fas fa-comments font-18 align-middle ml-1"></i></button>
                                        </div>
                                    </div>
                                </form>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-3 px-0 order-1 order-md-2 order-lg-2">
                    <Detail :report="report" @update="fetchReport" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ReportService from "@/services/resources/ReportService";
import Detail from '@/components/Reports/Detail';
import ReportAnswerService from "@/services/resources/ReportAnswerService";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import moment from 'moment';

const serviceAnswer = ReportAnswerService.build();
const serviceReport = ReportService.build();

export default {
    components:{
        Detail,
        VueUploadMultipleImage
    },
    data(){
        return{
            report:{
                open_like: 'CLIENT',
                client_id: null,
                client: {
                    first_name: null,
                    last_name: null
                },
                conds: [],
                enterprise_id: null,
                enterprise: {
                    name: null
                },
                subject: null,
                tower: null,
                status: 'PENDING',
                conformity_id: null,
                conformity:{
                    name: null
                },
                precedent_id: null,
                technical_id: null,
                technical:{
                    first_name: null,
                    last_name: null
                },
                description: null,
                date: null,
                floors: []
            },
            answer: null,
            images: []
        }
    },
    filters:{
      date(date){
          return moment(date).format('DD/MM/YYYY H:MM');
      }
    },
    methods:{
        uploadImageSuccess(formData, index, fileList) {
            this.images = fileList;
        },
        beforeRemove(index, done, fileList) {
            var r = confirm("Remover imagem?");
            if (r == true) {
                this.images.splice(index, 1);
                done();
            }
        },
        editImage(formData, index, fileList) {
            this.images = fileList;
        },
        getOpenLike(open_like){

            switch(open_like){
                case 'LIQUIDATOR':
                    return 'Síndico';
                case 'TECHNICAL':
                    return 'Técnico';
                case 'LESSE':
                    return 'Locatário';
                case 'CLIENT':  
                    return 'Cliente';
                default:
                    return '';
            }

        },
        fetchReport(){

            if(!this.id){
                return;
            }

            let data = {
                id: this.id
            };

            serviceReport
            .read(data)
            .then(resp => {
                this.report = resp;
                this.report.date = moment(this.report.date).format('YYYY-MM-DD[T]HH:mm');

            })
            .catch(err => {
                console.log(err)
            })
        },
        save(){

            this.$validator.validateAll().then((result) => {
                if (result) {

                    let data = {
                        answer: this.answer,
                        image: this.images[0],
                        report_id: this.id
                    };

                    serviceAnswer
                    .create(data)
                    .then(resp => {
                        this.$bvToast.toast('Sua responta foi adicionado com sucesso!', {
                            title: 'Resposta adicionada',
                            autoHideDelay: 5000,
                            type: 'success'
                        });
                        this.answer = null;
                        this.$validator.reset();
                        this.images = [];

                        this.fetchReport();
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
            });
        },
    },
    mounted(){
        this.id = this.$route.params.id;
        this.fetchReport();

    }
}
</script>